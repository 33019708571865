import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { GET_LOCALITIES } from "../../../GraphQL/settings/queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  OverlayTrigger,
} from "react-bootstrap";
import { QuestionCircleFill, LockFill } from "react-bootstrap-icons";

import Matches from "../../Matches";
import Toggle from "react-toggle";
import { Job, LocalityData, EModalFormType } from "../../../interfaces";
import {
  ADD_ROLE,
  AMEND_ROLE,
  IMPORT_ROLE,
} from "../../../GraphQL/roles/mutations";
import { GET_COMPANY_USERS } from "../../../GraphQL/settings/queries";
import { GET_ROLE_BY_ID, GET_PROJECT_JOB_BOARDS} from "../../../GraphQL/roles/queries";
import {
  Popover,
  SwitchForm,
  ModalFooterWrapper,
  ModalFooterLeft,
  ModalFooterRight,
} from "../../../sharedStyles";
import { industries } from "../../../const";
import DatePicker, { registerLocale } from "react-datepicker";
import enAU from "date-fns/locale/en-AU";
import confirmWithModal from "../../Global/confirmWithModal";
import DraftEditor from "../../DraftEditor";
import ReactSelect from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { EToastPosition, EToastColorStatus } from "../../../interfaces";
import config from "../../../config";
import { useFeatureFlagEnabled } from "posthog-js/react";

registerLocale("enAU", enAU);
interface Props {
  isNonATS: boolean;
  show: boolean;
  role: Job | null;
  onHide: () => void;
  reloadRoles: () => any;
  atsServer: string;
  matchId?: string;
  onCreateToast?: (
    color: EToastColorStatus,
    position: EToastPosition,
    message: string
  ) => void;
  onShowDelete: (data: boolean) => void;
  onSetDeleteId: (data: number) => void;
}

const SalaryInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const ToggleContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: left;

  & div {
    margin: 0 4px;
  }
`;

export const LabelNote = styled.span`
  color: #888;
  font-weight: normal;
  font-size: 16px;
`;

export const SummaryItem = styled.div`
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  background-color: #dcf2ef;
  margin-bottom: 10px;
  position: relative;
`;

const RoleDetailFormSchema = Yup.object().shape({
  localityId: Yup.string().required("Location is required"),
  industry: Yup.string().required("Industry is required"),
  summary: Yup.string()
    .test(
      "value",
      "Description is Required",
      (value) => value !== "<p><br></p>"
    )
    .required("Description is Required"),
  salaryMin: Yup.number().required("Required"),
  salaryMax: Yup.number()
    .required("Required")
    .moreThan(Yup.ref("salaryMin"), "Max value must be more than min value"),
  feeValue: Yup.number().required("Required"),
  split: Yup.number().required("Required").moreThan(30, "Minimum split is 30%"),
});

const permanentJobFormSchema = Yup.object().shape({
  startDate: Yup.date().required("Start date is required"),
  feeType: Yup.string().required("Fee type is required"),
});

const tempJobFormSchema = Yup.object().shape({
  placementStartDate: Yup.date().required("Start date is required"),
  placementEndDate: Yup.date()
    .min(
      Yup.ref("placementStartDate"),
      "End date must be later than start date"
    )
    .required("End date is required"),
  workingHours: Yup.string().required("Required"),
});

const newRoleDetailFormSchema = Yup.object().shape({
  clientName: Yup.string().required("Client name is required"),
  title: Yup.string().required("Job title is required"),
});

const RoleDetailModal = ({
  show,
  onHide,
  role,
  reloadRoles,
  atsServer,
  isNonATS,
  matchId,
  onCreateToast = () => {},
  onShowDelete,
  onSetDeleteId,
}: Props) => {
  const showDemoProjectsFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showDemoProjects
  );

  const [key, setKey] = useState<string>(
    matchId ? "roleMatches" : "roleDetails"
  );

  const {
    error   : errorCompany,
    loading : loadingCompany,
    data    : dataCompany,
  } = useQuery(GET_COMPANY_USERS, {
    onCompleted: (data) => setJobOwners(data?.companyUsers?.items),
  });

  const {
    error   : errorProjectBoards,
    loading : loadingProjectBoards,
    data    : dataProjectBoards,
  } = useQuery(GET_PROJECT_JOB_BOARDS);

  const { data: roleByIdData } = useQuery(GET_ROLE_BY_ID, {
    variables: {
      id: role?.id,
    },
  });

  let initJobOwners = [];
  if (role?.jobOwners){
    for (let i in role?.jobOwners)
      initJobOwners.push({value: role?.jobOwners[i].userId, label: role?.jobOwners[i].email});
  }

  const [jobOwnersValue, setJobOwnersValue] = useState(initJobOwners);
  const [jobOwners, setJobOwners] = useState<any>([]);
  let ownerOptions = [];
  if (dataCompany) ownerOptions = jobOwners.map((item: any) => ({value: item?.id, label: item?.email}));

  let initProjectBoards = [];
  if (roleByIdData) {
    for (let i in roleByIdData?.jobs?.items[0]?.projectBoards) {
      initProjectBoards.push({
        value: roleByIdData?.jobs?.items[0]?.projectBoards[i]?.id,
        label: roleByIdData?.jobs?.items[0]?.projectBoards[i]?.title,
      });
    }
  }

  const [projectBoardsValue, setProjectBoardsValue] = useState(initProjectBoards);
  const [projectBoards, setProjectBoards] = useState<any>([]);

  const [sharing, setSharing] = useState(false);
  const [atsRoleId, setAtsRoleId] = useState<string | null>(null);
  const [modalFormType, setModalFormType] = useState<EModalFormType>(EModalFormType.ID);

  const [amendRole] = useMutation(AMEND_ROLE);
  const [createRole] = useMutation(ADD_ROLE);
  const [importRole] = useMutation(IMPORT_ROLE);

  const { data } = useQuery<LocalityData>(GET_LOCALITIES);

  const localities = data?.localities?.items ? [...data.localities.items] : [];
  localities.sort((a, b) => {  
   return a.state.localeCompare(b.state) || a.name.localeCompare(b.name)
  });

  const [validationType, setValiationType] = useState<"TEMP" | "PERMANENT">(
    role?.workType === "TEMP" ? "TEMP" : "PERMANENT"
  );

  const tabSelect = (tab: string | null) => {
    if (tab) setKey(tab);
  };

  useEffect(() => {
    let ownersValue = [];
    if (role?.jobOwners){
      for (let i in role?.jobOwners)
        ownersValue.push({value: role?.jobOwners[i].userId, label: role?.jobOwners[i].email});
    }
    setJobOwnersValue(ownersValue);

    let projectBoardValue = [];
    if (roleByIdData) {
      for (let i in roleByIdData?.jobs?.items[0]?.projectBoards) {
        projectBoardValue.push({
          value: roleByIdData?.jobs?.items[0]?.projectBoards[i]?.id,
          label: roleByIdData?.jobs?.items[0]?.projectBoards[i]?.title,
        });
      }
    }
    setProjectBoardsValue(projectBoardValue);

    setSharing(role?.status === "SHARED");
  }, [role, roleByIdData]);

  useEffect(() => {
    let val = [];
    if (dataProjectBoards?.projectJobBoards?.items) {
      for (let i in dataProjectBoards?.projectJobBoards?.items) {
        val.push({
          value: dataProjectBoards?.projectJobBoards?.items[i]?.id,
          label: dataProjectBoards?.projectJobBoards?.items[i]?.title,
        });
      }
    }
    setProjectBoards(val);
  }, [dataProjectBoards]);

  let validationSchema =
    validationType === "PERMANENT"
      ? RoleDetailFormSchema.concat(permanentJobFormSchema)
      : RoleDetailFormSchema.concat(tempJobFormSchema);

  if (!role) {
    validationSchema =
      validationType === "PERMANENT"
        ? RoleDetailFormSchema.concat(permanentJobFormSchema).concat(
            newRoleDetailFormSchema
          )
        : RoleDetailFormSchema.concat(tempJobFormSchema).concat(
            newRoleDetailFormSchema
          );
  }

  const onDelete = () => {
    if (role?.id) onSetDeleteId(role?.id);
    onShowDelete(true);
    onHide();
  };

  const handleATSRole = () => {
    if (atsRoleId) {
      onCreateToast(
        EToastColorStatus.WARNING,
        EToastPosition.BOTTOM_END,
        "Sync requested for role: " + atsRoleId
      );

      importRole({
        variables: {
          jobId: parseInt(atsRoleId),
        },
      })
        .then(() => {
          reloadRoles();
          onCreateToast(
            EToastColorStatus.SUCCESS,
            EToastPosition.BOTTOM_END,
            "Role imported!"
          );
          onHide();
        })
        .catch((e) => {
          onCreateToast(
            EToastColorStatus.FAILED,
            EToastPosition.BOTTOM_END,
            "Error importing role. Please check the role ID and try again."
          );
          onHide();
        });
    } else {
      onCreateToast(
        EToastColorStatus.WARNING,
        EToastPosition.BOTTOM_END,
        "Please input a role ID to sync"
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: role
      ? {
          ...role,
          split: role?.split ? role?.split * 100 : undefined,
          feeValue:
            role?.feeValue && role?.feeType === "PERCENTAGE"
              ? role?.feeValue * 100
              : role?.feeValue,
          localityId: "" + role?.location?.localityId,
          summary: role?.summary ?? "",
        }
      : {
          clientName: "",
          title: "",
          workType: "PERMANENT",
          workingHours: "-",
          feeValue: 0,
          feeType: "PERCENTAGE",
          split: 0,
          localityId: "",
          audience: "PRIVATE",
          engagementType: "CONTINGENT",
          termsSigned: false,
          placementStartDate: "",
          placementEndDate: "",
          replacementGuarantee: false,
          startDate: "",
          salaryMin: 0,
          salaryMax: 0,
          industry: "",
          summary: "",
          guaranteeDetails: "",
          share: false,
          currency: "AUD",
          ownerUserIds: [],
          projectJobBoardIds: [],
        },
    onSubmit: async (values) => {
      if (values.share) {
        const confirmed = await confirmWithModal(
          <>
            <p>Before sharing a role, you confirm that</p>
            <ul>
              <li>
                You have removed any information identifying your client from
                the description
              </li>
              <li>
                there is a genuine casual, part-time, temporary, permanent or
                fixed-term opportunity.
              </li>
            </ul>
          </>
        );
        if (!confirmed) return;
      }

      if (!values.workingHours) {
        values.workingHours = "-";
      }
      let cFeeValue = values.feeValue;
      if (values.feeType === "PERCENTAGE" && values.feeValue) {
        cFeeValue = values.feeValue * 0.01;
      }
      let cSplit = values.split ? values.split * 0.01 : null;

      if (role) {
        amendRole({
          variables: {
            ...values,
            localityId: parseInt(values.localityId),
            feeValue: cFeeValue,
            split: cSplit,
          },
        })
          .then(() => {
            reloadRoles();
            onCreateToast(
              EToastColorStatus.SUCCESS,
              EToastPosition.BOTTOM_END,
              "Role updated!"
            );
            onHide();
          })
          .catch(() => {
            onCreateToast(
              EToastColorStatus.FAILED,
              EToastPosition.BOTTOM_END,
              "Error while updating role, please ensure all fields are filled"
            );
          });
      } else {
        createRole({
          variables: {
            ...values,
            localityId: parseInt(values.localityId),
            state: "-",
            suburb: "-",
            feeValue: cFeeValue,
            split: cSplit,
          },
        })
          .then(() => {
            reloadRoles();
            onCreateToast(
              EToastColorStatus.SUCCESS,
              EToastPosition.BOTTOM_END,
              "Role added successfully."
            );
            onHide();
          })
          .catch(() => {
            onCreateToast(
              EToastColorStatus.FAILED,
              EToastPosition.BOTTOM_END,
              "Error while created role, please ensure all fields are filled"
            );
          });
      }
    },
  });

  const fv = formik.values;

  let displayRetain = "-";
  let displaySend = "-";

  if (fv.feeType && fv.salaryMax && fv.salaryMin && fv.feeValue && fv.split) {
    if (fv.feeType === "PERCENTAGE") {
      const minFee = fv.salaryMin * fv.feeValue * 0.01;
      const maxFee = fv.salaryMax * fv.feeValue * 0.01;
      const retainPercent = 1.0 - fv.split * 0.01;
      const sendPercent = fv.split * 0.01;
      displayRetain = `$${(minFee * retainPercent).toFixed(0)} - $${(
        maxFee * retainPercent
      ).toFixed(0)}`;
      displaySend = `$${(minFee * sendPercent).toFixed(0)} - $${(
        maxFee * sendPercent
      ).toFixed(0)}`;
    } else {
      const retainPercent = 1.0 - fv.split * 0.01;
      const sendPercent = fv.split * 0.01;
      displayRetain = `$${(fv.feeValue * retainPercent).toFixed(0)}`;
      displaySend = `$${(fv.feeValue * sendPercent).toFixed(0)}`;
    }
  }

  const { errors } = formik;
  const ErrorWrapper = ({ field }: { field: keyof typeof errors }) => {
    return (
      <>
        {errors &&
        (formik.touched[field] ||
          field === "placementEndDate" ||
          field === "placementStartDate") ? (
          <div style={{ color: "red" }}>{errors[field]}</div>
        ) : null}
      </>
    );
  };

  if (!isNonATS && !role && modalFormType === EModalFormType.ID) {
    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header>
          <Modal.Title>New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Enter the ID of a role you would like to add to Role Relay.{" "}
            {/* TODO */}
            {/* <Button variant="link">Where do I find my role ID?</Button> */}
          </p>
          <Form.Group controlId="formClient">
            <Form.Label>Role ID*</Form.Label>
            <Form.Control
              type="text"
              name="roleId"
              onChange={(e) => setAtsRoleId(e.target.value)}
              required
            />
          </Form.Group>
          <br />
          <SwitchForm onClick={() => setModalFormType(EModalFormType.MANUAL)}>
            Add role manually instead
          </SwitchForm>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleATSRole}>
            Add Role
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        {role?.clientName ? (
          <>
            <Modal.Title>
              {role?.clientName} - {role?.title}
              <br /> {role?.location.displayName}
            </Modal.Title>
            <a
              href={`https://${atsServer}.jobadder.com/joborders/${role?.externalId
                ?.split(":")
                .slice(-1)}`}
              target="_blank"
              rel="noreferrer"
            >
              View this role in JobAdder
            </a>
          </>
        ) : (
          <Modal.Title>New Role</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {!isNonATS && !role &&
          <SwitchForm onClick={() => setModalFormType(EModalFormType.ID)}>
            Import role from your ATS instead
          </SwitchForm>
        }
        <Tabs activeKey={key} onSelect={tabSelect}>
          <Tab eventKey="roleDetails" title="Role Detail">
            <Form onSubmit={formik.handleSubmit}>
              <br />
              <Row>
                <Col>
                  <p>
                    <strong>Note:</strong> Unless marked <LockFill />, these
                    details will be visible to other agencies on the platform
                    when sharing is activated. Please ensure you check all
                    fields (particularly the Description) for any client
                    sensitive or identifiable information.
                  </p>
                </Col>
              </Row>
              {!role && (
                <>
                  <Row>
                    <Col>
                      {" "}
                      <Form.Group controlId="formClient">
                        <Form.Label>Client*</Form.Label>{" "}
                        <LabelNote>
                          <LockFill />
                        </LabelNote>{" "}
                        <Form.Control
                          type="text"
                          name="clientName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                        <ErrorWrapper field="clientName" />
                      </Form.Group>
                    </Col>
                    <Col>
                      {" "}
                      <Form.Group controlId="formJobTitle">
                        <Form.Label>Job Title*</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                        <ErrorWrapper field="title" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                </>
              )}

              <Row>
                <Col>
                  <Form.Group controlId="formLocation">
                    <Form.Label>Location*</Form.Label>
                    <Form.Control
                      as="select"
                      name="localityId"
                      placeholder="Select location"
                      value={fv.localityId}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      <option></option>
                      {localities.map((locality) => (
                        <option key={locality.id} value={locality.id}>
                          {locality.name} ({locality.state})
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorWrapper field="localityId" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formIndustry">
                    <Form.Label>Industry*</Form.Label>
                    <Form.Control
                      as="select"
                      name="industry"
                      placeholder="Select industry"
                      value={fv.industry}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      {" "}
                      <option key="" value=""></option>
                      {industries.map((industry) => (
                        <option key={industry} value={industry}>
                          {industry}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorWrapper field="industry" />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group controlId="formWorkType">
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="workType"
                      placeholder="Select location"
                      value={fv.workType}
                      onChange={(...args) => {
                        setValiationType(args[0].target.value as any);
                        formik.handleChange(...args);
                        formik.validateField("salaryMax");
                        if (args[0].target.value === "TEMP") {
                          formik.setFieldValue("feeType", "FIXED");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    >
                      <option value=""></option>
                      <option value="PERMANENT">Permanent</option>
                      <option value="TEMP">Temp</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formRoleSalary">
                    <Form.Label>
                      {fv.workType === "PERMANENT"
                        ? "Salary range (min. $40,000)*"
                        : "Rate range ($/hr)"}
                    </Form.Label>
                    <SalaryInputs>
                      <Form.Control
                        type="number"
                        min={fv.workType === "PERMANENT" ? "40000" : "0"}
                        name="salaryMin"
                        placeholder="Min."
                        value={fv.salaryMin}
                        onChange={formik.handleChange}
                        onBlur={(...args) => {
                          formik.handleBlur(...args);
                          formik.validateField("salaryMax");
                        }}
                        required
                      />
                      <div>-</div>
                      <Form.Control
                        type="number"
                        name="salaryMax"
                        placeholder="Max."
                        value={fv.salaryMax}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                      />
                      <Form.Control
                        as="select"
                        name="currency"
                        value={fv.currency}
                        onChange={(...args) => {
                          formik.handleChange(...args);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="AUD">AUD</option>
                        <option value="NZD">NZD</option>
                      </Form.Control>
                    </SalaryInputs>
                    <ErrorWrapper field="salaryMin" />
                    <ErrorWrapper field="salaryMax" />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group controlId="formRoleSummary">
                    <Form.Label>
                      Description and Agency Expectations*
                    </Form.Label>
                    <DraftEditor formik={formik} />
                  </Form.Group>
                  <ErrorWrapper field="summary" />
                </Col>
              </Row>
              <br />
              <Row>
                {fv.workType === "PERMANENT" ? (
                  <>
                    <Col>
                      <Form.Group controlId="formRoleStartDate">
                        <Form.Label>Start date*</Form.Label>
                        <DatePicker
                          locale="enAU"
                          dateFormat="dd/MM/yyyy"
                          selected={fv.startDate ? new Date(fv.startDate) : null}
                          onChange={(date) => {
                            if (date) {
                              formik.setFieldValue(
                                "startDate",
                                date.toISOString()
                              );
                              formik.setFieldValue(
                                "placementStartDate",
                                date.toISOString()
                              );
                              formik.setFieldValue(
                                "placementEndDate",
                                date.toISOString()
                              );
                            }
                          }}
                          className="form-control"
                          minDate={new Date()}
                          customInput={
                            <Form.Control
                              type="text"
                              id="validationCustom01"
                              placeholder="Select start date"
                            />
                          }
                        />
                      </Form.Group>
                    </Col>
                    {!loadingCompany && !errorCompany && dataCompany &&
                      <Col>
                        <Form.Group>
                          <Form.Label>Owners</Form.Label>
                          <ReactSelect
                            onChange={(data: any) => {
                              let ownerIds = [];
                              for (let i in data) ownerIds.push(Number(data[i]?.value));
                              formik.setFieldValue("ownerUserIds", ownerIds);
                              setJobOwnersValue(data);
                            }}
                            name="jobOwners"
                            options={ownerOptions}
                            isMulti={true}
                            value={jobOwnersValue}
                          />
                        </Form.Group>
                      </Col>
                    }
                  </>
                ) : (
                  <>
                    <Col>
                      <Form.Group controlId="formRoleStartDate">
                        <Form.Label>Placement start date*</Form.Label>
                        <DatePicker
                          locale="enAU"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            fv.placementStartDate
                              ? new Date(fv.placementStartDate)
                              : null
                          }
                          onChange={(date) => {
                            if (date) {
                              formik.setFieldValue(
                                "placementStartDate",
                                date.toISOString()
                              );
                              formik.setFieldValue(
                                "startDate",
                                date.toISOString()
                              );
                            }
                          }}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          minDate={new Date()}
                          customInput={
                            <Form.Control
                              type="text"
                              id="validationCustom01"
                              placeholder="Select start date"
                            />
                          }
                        />
                        <ErrorWrapper field="placementStartDate" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formRoleEndDate">
                        <Form.Label>Placement end date*</Form.Label>
                        <DatePicker
                          locale="enAU"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            fv.placementEndDate
                              ? new Date(fv.placementEndDate)
                              : null
                          }
                          onChange={(date) => {
                            if (date) {
                              formik.setFieldValue(
                                "placementEndDate",
                                date.toISOString()
                              );
                            }
                          }}
                          className="form-control"
                          minDate={new Date()}
                          customInput={
                            <Form.Control
                              type="text"
                              id="validationCustom02"
                              placeholder="Select end date"
                            />
                          }
                        />
                        <ErrorWrapper field="placementEndDate" />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              <br />
              {fv.workType === "PERMANENT" ? (
                <>
                  <Row>
                    <Col xs={12} md={4} lg={4}>
                      <Form.Group controlId="formRoleFeeType">
                        <Form.Label>
                          Fee type*{" "}
                          <LabelNote>
                            <LockFill />
                          </LabelNote>{" "}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  The fee structure in which you are operating
                                  with your client. This information will not be
                                  revealed to the candidate agency.
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <QuestionCircleFill />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="feeType"
                          value={fv.feeType}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        >
                          <option value="FIXED">Fixed</option>
                          <option value="PERCENTAGE">% of salary</option>
                        </Form.Control>
                        <ErrorWrapper field="feeType" />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={4} lg={4}>
                      <Form.Group controlId="formTotalFee">
                        <Form.Label>
                          Total fee ({fv.feeType !== "FIXED" ? "%" : "$"}
                          )*{" "}
                          <LabelNote>
                            <LockFill />
                          </LabelNote>{" "}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  The total fee you are receiving from the
                                  client inclusive of GST. This is not revealed
                                  to the Candidate Agency.
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <QuestionCircleFill />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          name="feeValue"
                          placeholder={
                            fv.feeType !== "FIXED"
                              ? "Enter % fee"
                              : "Enter total fee ($)"
                          }
                          value={fv.feeValue}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                        <ErrorWrapper field="feeValue" />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <Form.Group controlId="formAgencySplit">
                        <Form.Label>
                          Candidate agency split (%)*{" "}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  The percentage of the fee you will pay the
                                  Candidate Agency inclusive of GST for a
                                  successful placement. Minimum amount is 30%
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <QuestionCircleFill />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="split"
                          placeholder="Enter relay split (%)"
                          value={fv.split}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min="30"
                          max="100"
                          required
                        />
                        <ErrorWrapper field="split" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <SummaryItem>
                        Client agency retain:
                        <br />
                        <strong>{displayRetain}</strong>
                      </SummaryItem>
                    </Col>
                    <Col>
                      <SummaryItem>
                        Candidate agency receivies:
                        <br />
                        <strong>{displaySend}</strong>
                      </SummaryItem>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col>
                      <Form.Group controlId="formWorkingHours">
                        <Form.Label>
                          Working hours* (e.g. full time, part time, hrs per
                          week){" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="workingHours"
                          placeholder="Enter working hours"
                          value={fv.workingHours}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                      </Form.Group>
                      <ErrorWrapper field="workingHours" />
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Owners</Form.Label>
                        <ReactSelect
                          onChange={(data: any) => {
                            let ownerIds = [];
                            for (let i in data) ownerIds.push(Number(data[i]?.value));
                            formik.setFieldValue("ownerUserIds", ownerIds);
                            setJobOwnersValue(data);
                          }}
                          name="jobOwners"
                          options={ownerOptions}
                          isMulti={true}
                          value={jobOwnersValue}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group controlId="formAgencyMargin">
                        <Form.Label>
                          Agency margin* ($ per hour){" "}
                          <LabelNote>
                            <LockFill />
                          </LabelNote>{" "}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  the fee you charge on to your client per hour
                                  worked by the candidate{" "}
                                  <strong>not including</strong> pay rate or on
                                  costs
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <QuestionCircleFill />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          name="feeValue"
                          placeholder="Enter margin ($ per hour)"
                          value={fv.feeValue}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                      </Form.Group>
                      <ErrorWrapper field="feeValue" />
                    </Col>
                    <Col>
                      <Form.Group controlId="formAgencySplit">
                        <Form.Label>
                          Candidate agency split (%)*{" "}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  The percentage of the Agency Margin you will
                                  pay the Candidate Agency inclusive of GST for
                                  a successful placement. Minimum amount is 30%
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <QuestionCircleFill />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="split"
                          placeholder="Enter relay split (%)"
                          value={fv.split}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min="30"
                          max="100"
                          required
                        />
                        <ErrorWrapper field="split" />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              <hr />
              <Row>
                <Col>
                  <Form.Label>
                    Have you been engaged on an exclusive, retained or
                    contingent basis to fill this role?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="engagementType"
                    placeholder="Select engagement type"
                    value={fv.engagementType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select engagement type ...</option>
                    <option value="EXCLUSIVE">Exclusive</option>
                    <option value="CONTINGENT">Contingent</option>
                    <option value="RETAINED">Retained</option>
                  </Form.Control>

                  {/* <ToggleContainer>
                    <span>Retained</span>
                    <Toggle
                      className="even-toggle"
                      name="engagementType"
                      icons={false}
                      onChange={(evt) => {
                        formik.setFieldValue(
                          "engagementType",
                          evt.target.checked ? "CONTINGENT" : "RETAINED"
                        );
                      }}
                      checked={fv.engagementType === "CONTINGENT"}
                    />{" "}
                    <span>Contingent</span>
                  </ToggleContainer> */}
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div>Do you have signed terms with the client?</div>
                  <ToggleContainer>
                    <span>No</span>
                    <Toggle
                      icons={false}
                      onChange={(evt) => {
                        formik.setFieldValue("termsSigned", evt.target.checked);
                      }}
                      checked={fv.termsSigned}
                    />{" "}
                    <span>Yes</span>
                  </ToggleContainer>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div>
                    Do you have a replacement guarantee with the client?
                  </div>
                  <ToggleContainer>
                    <span>No</span>
                    <Toggle
                      icons={false}
                      onChange={(evt) => {
                        formik.setFieldValue(
                          "replacementGuarantee",
                          evt.target.checked
                        );
                      }}
                      checked={fv.replacementGuarantee}
                    />{" "}
                    <span>Yes</span>
                  </ToggleContainer>
                </Col>
              </Row>
              {fv.replacementGuarantee && (
                <>
                  {" "}
                  <br />
                  <Row>
                    <Col>
                      <Form.Label>
                        Details of any replacement or other client guarantees
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="guaranteeDetails"
                        placeholder="Enter details..."
                        value={fv.guaranteeDetails}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                      />
                    </Col>
                  </Row>
                </>
              )}

              <hr />
              <Row>
                <Col>
                  <Form.Label>Sharing</Form.Label>{" "}
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Popover>
                        <Popover.Body>
                          <strong>Shared for Collaboration</strong> will push
                          this role to the Role Relay Network based on your{" "}
                          <strong>Audience</strong> selection. Roles you do not
                          wish to share with the network can be set to{" "}
                          <strong>Draft</strong>.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <QuestionCircleFill />
                  </OverlayTrigger>
                  <ToggleContainer>
                    <span>Draft</span>
                    <Toggle
                      name="shared"
                      icons={false}
                      onChange={(evt) => {
                        setSharing(evt.target.checked);
                      }}
                      checked={sharing}
                    />{" "}
                    <span>Shared for collaboration</span>
                  </ToggleContainer>
                </Col>
                <Col>
                  <Form.Group controlId="formAudience">
                    <Form.Label>Audience</Form.Label>{" "}
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <p>
                              Select which audience you would like to share the
                              Role with.
                            </p>
                            <ul>
                              <li>
                                <strong>Private</strong> - only flagged for Role
                                Relay automatic matching. You will receive
                                notifications of candidates Role Relay detects
                                for this role, but the role will not be
                                searchable for other agencies on the Public or
                                Partner Roles Boards.
                              </li>
                              <li>
                                <strong>Partners Only</strong> - flagged for
                                Role Relay automatic matching, and listed on the
                                Partner Roles Board. The role will not be
                                searchable on the Public Roles Board.
                              </li>
                              <li>
                                <strong>Public</strong> - flagged for matching,
                                and listed on the Partner and Public Roles
                                Board.
                              </li>
                            </ul>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <QuestionCircleFill />
                    </OverlayTrigger>
                    <Form.Control
                      as="select"
                      name="audience"
                      placeholder="Select type"
                      value={fv.audience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="PRIVATE">Private</option>
                      <option value="PARTNER">Partners only</option>
                      <option value="PUBLIC">Public (incl. Partners)</option>
                      {showDemoProjectsFlag && (
                        <option value="PROJECT">
                          Power Recruitment Project
                        </option>
                      )}
                    </Form.Control>
                    <ErrorWrapper field="audience" />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                </Col>
                <Col>
                  {!loadingProjectBoards && !errorProjectBoards && dataProjectBoards && (
                    <Form.Group>
                      <Form.Label>Projects</Form.Label>
                      <ReactSelect
                        onChange={(data: any) => {
                          let jobBoardIds = [];
                          for (let i in data)
                            jobBoardIds.push(data[i]?.value);
                          formik.setFieldValue("projectJobBoardIds", jobBoardIds);
                          setProjectBoardsValue(data);
                        }}
                        name="projectBoards"
                        isMulti={true}
                        options={projectBoards}
                        value={projectBoardsValue}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </Form>
            <hr />
            <p>
              <strong>What happens next?</strong>
            </p>
            <p>
              Your Role will be shared with the Role Relay network based on the{" "}
              <strong>Audience</strong> you have selected.
            </p>
            <p>
              The dollar value of the fee or fee range the candidate agency
              stands to receive <strong>will</strong> be included in the
              listing.
            </p>
            <p>
              The fee type and fee split arrangement <strong>will not</strong>{" "}
              be disclosed to the candidate agency at any time
            </p>
          </Tab>

          {role && (
            <Tab eventKey="roleMatches" title="Matches">
              <Matches roleId={role?.id} matchId={matchId} />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>

      {key === "roleDetails" && (
        <Modal.Footer>
          <ModalFooterWrapper>
            <ModalFooterLeft>
              {role && (
                <Row>
                  <Col>
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.preventDefault();
                        onDelete();
                      }}
                    >
                      Remove this role from Role Relay
                    </Button>
                  </Col>
                </Row>
              )}
            </ModalFooterLeft>
            <ModalFooterRight>
              <Button
                className="mx-1"
                variant="secondary"
                onClick={onHide}
              >
                Cancel
              </Button>
              <Button
                className="mx-1"
                variant="primary"
                onClick={() => {
                  formik.setFieldValue("share", sharing);
                  formik.submitForm();
                }}
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </ModalFooterRight>
          </ModalFooterWrapper>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default RoleDetailModal;
