import React from "react";
import { Container, Accordion } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import config from "../../config";
import UserSettings from "./UserSettings";
import Connections from "./Connections";
import Partnerships from "./Partnerships";
import Subscriptions from "./Subscriptions/index";
import Communications from "./Communications";
import IndustrySettings from "./Industry";
import Projects from "./Projects";
import { GET_PROFILE } from "../../GraphQL/settings/queries";
import { useLocation, useParams } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";

const Settings = () => {
  const { pathUrl } = useParams();
  const location = useLocation();
  const industryState = location.state as any;
  const { loading, error, data } = useQuery(GET_PROFILE);
  const showAccordionFlag = useFeatureFlagEnabled(config?.postHog?.featureFlags?.showAccordionSettings);
  const hasFeatureProject = data?.profile?.company?.settings?.features?.projects;
  const showSubscriptions = false;

  if (loading || error) return null;

  let defaultKey;
  if (industryState?.byIndustry) {
    defaultKey = "industry";
  } else if (pathUrl) {
    defaultKey = pathUrl;
  } else {
    defaultKey = "user-details";
  }

  return (
    <Container>
      <Accordion defaultActiveKey={defaultKey} flush>
        <Accordion.Item eventKey="user-details">
          <Accordion.Header>User Details</Accordion.Header>
          <Accordion.Body>
            <UserSettings profile={data.profile} />
          </Accordion.Body>
        </Accordion.Item>
        {showAccordionFlag && (
          <Accordion.Item eventKey="communications">
            <Accordion.Header>Communications</Accordion.Header>
            <Accordion.Body>
              <Communications />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {data?.profile?.role === "AccountHolder" && (
          <>
            <Accordion.Item eventKey="connections">
              <Accordion.Header>Connections</Accordion.Header>
              <Accordion.Body>
                <Connections />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="partnerships">
              <Accordion.Header>Partnerships</Accordion.Header>
              <Accordion.Body>
                <Partnerships companyId={data?.profile?.companyId} />
              </Accordion.Body>
            </Accordion.Item>
            {showAccordionFlag && showSubscriptions && (
              <Accordion.Item eventKey="subscriptions">
                <Accordion.Header>Subscriptions</Accordion.Header>
                <Accordion.Body>
                  <Subscriptions profile={data?.profile} />
                </Accordion.Body>
              </Accordion.Item>
            )}
          </>
        )}
        {showAccordionFlag && (
          <Accordion.Item eventKey="industry">
            <Accordion.Header>Industry Preferences</Accordion.Header>
            <Accordion.Body>
              <IndustrySettings profile={data?.profile} />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {data?.profile?.role === "AccountHolder" && hasFeatureProject && (
          <Accordion.Item eventKey="projects">
            <Accordion.Header>Projects</Accordion.Header>
            <Accordion.Body>
              <Projects />
            </Accordion.Body>
          </Accordion.Item>
        )}
    </Accordion>
  </Container>
  )
};

export default Settings;
